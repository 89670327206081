
import { defineComponent } from 'vue';

import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import SearchTable from '@/components/wizard/SearchTable.vue';
import * as Yup from 'yup';
import ApiService from '@/core/services/ApiService';
import { VueCookieNext } from 'vue-cookie-next';
import { apiEndpoint } from '@/mixin/apiMixin.js';

export default defineComponent({
  mixins: [apiEndpoint],
  name: 'submitted-claim-1',
  components: {
    Datatable,
    SearchTable,
  },
  data() {
    return {
      batch: {},
        bill: {
        claim_no:'',
        tranche_id: '',
        entity_contract_id: '',
        entity_id: '',
          bill_no: '',
      },
      tranches: [],
      entityInfos: [],
      contractInfos: [],
      allBillNo: [] as any,
      billList: [],
      allBill: [
        {
          trainee_count: '',
          bill_amount: '',
          contribution_percentage: '',
          contribution_amount: '',
          net_payable: '',
        },
      ],
      status: '',
      load: false,
      loading: false,
      tolatTrainee: 0,
      tolatBillAmount: 0,
      contributionPay: 0,
      totalContributionAmount: 0,
      totalPayableAmount: 0,
      showBillList: false,
    };
  },
  async created() {
    await this.getTranche();
    await this.getAssociation();
  },
  methods: {
    async getTranche() {
      this.load = true;
      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          this.tranches = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getAssociation() {
      this.load = true;
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      await ApiService.get('entity/list?entity_id=' + entity_id)
        .then((response) => {
          this.entityInfos = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEntityContract() {
      this.load = true;
      await ApiService.get(
        'entity/contract/list?entity_info_id=' +
          this.bill.entity_id +
          '&tranche_id=' +
          this.bill.tranche_id
      )
        .then((response) => {
          this.contractInfos = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getAllBillNo() {
      this.load = true;
      let institute_info_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      if (this.bill.entity_id && this.bill.tranche_id && this.bill.entity_contract_id && this.bill.claim_no) {
        await ApiService.get(
          'bill/institute_bill_list?entity_id=' +
          this.bill.entity_id +
          '&tranche_id=' +
          this.bill.tranche_id +
          '&entity_contract_id=' +
          this.bill.entity_contract_id +
          '&claim_no=' +
          this.bill.claim_no
        )
          .then((response) => {
            this.allBillNo = response.data.data;
            this.load = false;
            this.showBillList = true;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      }
      else {
      
        Swal.fire({
          title: "Error!",
          html: "Please select required field.",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Close",
          customClass: {
            confirmButton: "btn btn-danger",
          },
        });
      }
    },
    async getBillList() {
      this.load = true;
      this.tolatTrainee = 0;
      this.tolatBillAmount = 0;
      this.contributionPay = 0;
      this.totalContributionAmount = 0;
      this.totalPayableAmount = 0;
      let institute_info_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      if (this.bill.claim_no) {
        await ApiService.get(
          'bill/claim_1/list?entity_id=' +
            this.bill.entity_id +
            '&tranche_id=' +
            this.bill.tranche_id +
            '&bill_sequence_no=' +
            this.bill.bill_no +
            '&entity_contract_id=' +
             this.bill.entity_contract_id +
            '&institute_info_id=' +
            institute_info_id +
            '&submitted_status=1'
        )
          .then((response) => {
            if ((response.data.data[0].active_status == 2)) {
              this.status = 'Your bill is waiting for approval.';
            } else if ((response.data.data[0].active_status == 3)) {
              this.status = 'Your bill has been Approved.';
            } else {
              this.status = 'Your bill has been Submitted.';
            }
            this.billList = response.data.data;
            this.allBill = response.data.data;
            this.allBill.forEach((el) => {
              this.tolatTrainee += ~~el.trainee_count;
              this.tolatBillAmount += parseFloat(el.bill_amount);
              this.contributionPay = parseFloat(el.contribution_percentage);
              this.totalContributionAmount += parseFloat(
                el.contribution_amount
              );
              this.totalPayableAmount += parseFloat(el.net_payable);
            });
            this.showBillList = true;
            this.load = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } else {
        this.load = false;
        Swal.fire({
          title: 'Error!',
          html: 'Please select a bill.',
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Close',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        });
      }
    },
    Reset(id) {

      let data = {
        bill_id: id,
         tranche_id: this.bill.tranche_id,
        claim_no: this.bill.claim_no,
        entity_id: this.bill.entity_id,
        entity_contract_id: this.bill.entity_contract_id,
        bill_sequence_no: this.bill.bill_no,
      
      };
      if (id) {
        Swal.fire({
          title: 'Are you sure you want to reset this Bill?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Reset!',
        }).then((result) => {
          if (result.isConfirmed) {
            ApiService.post(
              'configurations/super_admin/bill_reset',
              data
            )
              .then((response) => {
                Swal.fire('Success!', response.data.data, 'success').then(
                  () => {
                    this.getAllBillNo();
                  }
                );
              })
              .catch(({ response }) => {
                console.log(response);
              });
          }
        });
      }
    },
   async downloadPdf() {
      this.loading = true;
      let formData = new FormData();
      formData.set('entity_id', this.bill.entity_id);
      formData.set('tranche_id', this.bill.tranche_id);
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        formData.set('institute_info_id', VueCookieNext.getCookie('_institute_info_id'));
      }
      formData.set('entity_contract_id', this.bill.entity_contract_id);
      formData.set('bill_sequence_no', this.bill.bill_no);
      await ApiService.post('bill/claim_1/claim_1_pdf', formData)
        .then((response) => {
          Swal.fire({
            title: 'Download Successfull!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'View/Download PDF',
          }).then((result) => {
            if (result.isConfirmed) {
              this.loading = false;

              let path = `${this.VUE_APP_API_URL}/${response.data}`;

              window.open(path, '_blank');
            }
          });
        })
        .catch(({ response }) => {
          console.log(response);
           this.loading = false;
        });
    },
  },
  setup() {},
});
